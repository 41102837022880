import $ from "jquery";

// form validation
$("#form-contact").on("submit", function(e) {
  let $name = $('input[name="name"]');
  let $email = $('input[name="email"]');
  let $message = $('textarea[name="message"]');

  let fields = [$name, $email, $message];

  for(let field in fields) {
    let $t = fields[field];
    $t.removeClass("error");

    if ($t.val() == "") {
      $t.addClass("error");
      e.preventDefault();
    }
  }

  if ($name.val() != "" && $email.val() != "" && $message.val() != "") {
    return true;
  }

  return false;
});
